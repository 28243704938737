<template>
	<div class="page" ref="page" :class="navBarFixed ? 'navbar-bottom ' : 'navbar-top'">
		<div class="header flex">
			<div class="content flex-space-between">
				<img :src="$store.state.config.logo"/>
				<!-- <div class="name flex-direction-column">
					<span>江苏省田径运动协会</span>
					<p>Jiansu Athletics Association</p>
				</div> -->
				<div class="search flex">
					<input v-model="key_words" type="text" placeholder="请输入关键词"/>
					<img class="icon" src="../../assets/image/icon/13.png" @click="search"/>
				</div>
			</div>
		</div>

		<div class="navbar" @mouseleave="mouseleave">
			<div class="content flex">
				<div
					class="item"
					:class="{ active: index == 5 }"
					:style="index == currentIndex ? '' : 'background-image:none'"
					v-for="(item, index) in navbar"
					:key="index"
					@click="itemClick(index, item)"
					@mouseenter="mouseenter(index)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="content">
				<div ref="select" class="select flex-direction-column" @mouseleave="mouseleave">
					<div class="select-item" @click="selectClick(item, index)" v-for="(item, index) in selectList" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>
		<div class="banner" v-if="banner && $route.path != '/search'">
			<img :src="banner"/>
		</div>
		<div class="position" v-if="$route.meta.isPosition" :style="{ marginTop: !banner ? '210px' : '0' }">
			<div class="content flex-space-between">
				<div class="position-left flex">
					<img src="@/assets/image/icon/06.png"/>
					<p>您现在的位置：</p>
					<div class="position-item flex" v-for="(item, index) in pathArr" :key="index">
            <span :style="index == pathArr.length - 1 ? 'color:#D6AD6E' : ''" @click="routerClick(item.path)">
              {{ item }}
            </span>
						<span v-if="index != pathArr.length - 1">></span>
					</div>
				</div>
				<div class="position-right flex" @click="backClick">
					<span>返回</span>
					<img src="@/assets/image/icon/07.png"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {postColumn, getBanner} from '@/service/api/index'
import {EventBus} from '@/utils/event-bus'

export default {
	data() {
		return {
			currentIndex: 0,
			navbar: [],
			pathArr: [],
			selectList: [],
			mouseIndex: 0,
			banner: '',
			navBarFixed: false,
			key_words: this.$route.query.key_words ?? ''
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		// 顶部导航
		this.postColumn()
		EventBus.$on('aside', (res) => {
			this.$set(this.pathArr, 1, res.name)
			sessionStorage.setItem('pathArr', JSON.stringify(this.pathArr))
		})

		EventBus.$on('pathArr', (res) => {
			this.pathArr = [res.one ?? res[0], res.two ?? res[1]]
		})
	},
	destroyed() {
		EventBus.$off('aside')
		EventBus.$off('pathArr')
		window.removeEventListener('beforeunload')
	},

	methods: {
		getBanner(id) {
			getBanner({id}).then((res) => {
				this.banner = res.msg
			})
		},
		// 顶部导航
		postColumn() {
			postColumn().then((res) => {
				this.navbar = Object.freeze(res.msg)
				// console.log(this.navbar)
			})
		},
		// watchScroll() {
		//   // 滚动的距离
		//   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		//   // 容器的高度
		//   var offsetTop = this.$refs.page.offsetHeight
		//   // 网页高度
		//   var bodyHeight = document.body.clientHeight
		//   //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
		//   if (scrollTop > offsetTop && bodyHeight > 1740) {
		//     this.navBarFixed = true
		//   } else {
		//     if (this.navBarFixed) {
		//       document.documentElement.scrollTop = 0
		//     }
		//     this.navBarFixed = false
		//   }
		// },
		itemClick(index, item) {
			this.currentIndex = index
			// this.getBanner(item.id)
			if (item.type == 0) {
				this.getBanner(1)
				this.$router.push('/home')
			}

			// this.$refs.select.style.display = 'none'
			sessionStorage.setItem('navbarIndex', this.currentIndex)
		},
		mouseenter(index) {
			// console.log(index, this.selectList)
			this.mouseIndex = index
			this.$refs.select.style.opacity = 1
			this.$refs.select.style.display = 'block'
			this.selectList = this.navbar[index].child
			if (index < 5) {
				this.$refs.select.style.left = 145 + (index - 1) * 175 + 'px'
			} else if (index == 5) {
				this.$refs.select.style.left = 155 + (index - 1) * 175 + 'px'
			} else {
				this.$refs.select.style.left = 165 + (index - 1) * 175 + 'px'
			}
		},
		mouseleave() {
			this.$refs.select.style.opacity = 0
		},
		selectClick(item, index) {
			this.currentIndex = this.mouseIndex
			this.$store.state.listId = item.id
			this.$refs.select.style.display = 'none'
			sessionStorage.setItem('navbarIndex', this.currentIndex)

			if (item.type == 2) {
				return window.open(item.url, '_blank')
			} else if (item.status == 0) {
				this.getBanner(item.column_id)
				this.$router.push({
					name: 'plist'
				})
			} else if (item.status == 1) {
				this.getBanner(1)
				this.$router.push({
					name: 'pudetail',
					params: {
						info: item
					}
				})
			}

			if (item.type != 2) {
				this.pathArr = [this.navbar[this.mouseIndex].name, item.name]
				sessionStorage.setItem('pathArr', JSON.stringify(this.pathArr))
				setTimeout(() => {
					EventBus.$emit('selectClick', {
						index,
						mouseIndex: this.mouseIndex
					})
				}, 200)
				setTimeout(() => {
					let obj = {
						name: this.navbar[this.mouseIndex].name,
						id: item.id
					}
					// item.name = this.navbar[this.mouseIndex].name
					EventBus.$emit('navbar', obj)
				}, 300)
			}
		},
		backClick() {
			this.$router.go(-1)
		},
		routerClick(link) {
			console.log(link)
		},
		beforeunloadFn() {
			let pathArr = sessionStorage.getItem('pathArr')
			this.pathArr = JSON.parse(pathArr)
		},
		search() {
			if (!this.key_words) return this.$message('请输入关键词')
			this.$router.push({
				path: '/search',
				query: {
					key_words: this.key_words
				}
			})
		}
	},
	watch: {
		// val
		$route() {
			this.currentIndex = sessionStorage.getItem('navbarIndex')
		}
	}
}
</script>

<style lang="less" scoped>
@keyframes topIndown {
	0% {
		-webkit-transform: translate3d(0, -20%, 0);
		transform: translate3d(0, -20%, 0);
		opacity: 0;
	}
	100% {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}

@keyframes downIntop {
	0% {
		-webkit-transform: translate3d(0, 10%, 0);
		transform: translate3d(0, 10%, 0);
		// opacity: 0;
	}
	100% {
		-webkit-transform: none;
		transform: none;
		// opacity: 1;
	}
}

.search {
	border-radius: 3px;
	background-color: #f9f9f9;

	input {
		border: none;
		outline: none;
		margin: 7px 10px;
		background-color: transparent;
	}

	.icon {
		width: 18px !important;
		height: 18px !important;
		margin-right: 11px;
	}
}

.page {
	// position: fixed;
	// left: 0;
	// top: 0;
	// right: 0;
	width: 100%;
	position: relative;
	z-index: 99;
	overflow: hidden;
}

.navbar-bottom {
	// animation: topIndown 0.6s normal;
	position: sticky;
	top: 0;
	z-index: 99;
}

.navbar-top {
	// animation: downIntop 0.6s normal;
}

.header {
	width: 100%;
	height: 150px;
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	background: rgba(255, 255, 255);

	.content {
		height: 92px;

		img {
			width: 400px;
			height: 90px;
		}

		// img {
		//   width: 92px;
		//   height: 92px;
		// }
		.name {
			margin-left: 6px;

			span {
				height: 48px;
				line-height: 48px;
				font-size: 34px;
				color: #000000;
			}

			p {
				height: 32px;
				line-height: 32px;
				font-size: 23px;
			}
		}
	}
}

.navbar {
	width: 100%;
	height: 60px;
	top: 150px;
	position: fixed;
	background: #e50114;

	.content {
		position: relative;

		.select {
			position: absolute;
			width: 228px;
			left: 150px;
			z-index: 10;
			opacity: 0;
			transition: all 0.35s;

			.select-item {
				text-align: center;
				width: 228px;
				height: 50px;
				line-height: 50px;
				font-size: 18px;
				background: rgba(255, 255, 255);
				margin-bottom: 2px;
				cursor: pointer;

				&:hover {
					color: #e50114;
				}
			}
		}
	}

	.active {
		width: 204px !important;
	}

	.item {
		// padding: 0 10px;
		width: 174px;
		height: 60px;
		line-height: 60px;
		text-align: center;
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		background-image: url('../../assets/image/img/14.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		&:hover {
			background-image: url('../../assets/image/img/14.png') !important;
		}
	}
}

.banner {
	width: 100%;
	height: 250px;
	object-fit: cover;
	margin-top: 210px;
}

.position {
	width: 100%;
	background: #fff;
	font-size: 16px;
	margin-bottom: 20px;
	z-index: 0;
	// top: 0px;
	// position: relative;
	// position: absolute;
	.position-left {
		height: 70px;

		img {
			width: 28px;
			height: 28px;
			margin-right: 10px;
		}

		p {
			height: 22px;
			line-height: 22px;
			color: #777777;
		}

		.position-item {
			cursor: pointer;

			span {
				height: 22px;
				line-height: 22px;
				color: #777777;
				margin-right: 10px;
			}
		}
	}

	.position-right {
		cursor: pointer;

		span {
			color: #d6ad6e;
			height: 22px;
			font-size: 14px;
			line-height: 22px;
		}

		img {
			width: 13px;
			height: 10px;
			margin-left: 5px;
		}
	}
}
</style>
