<template>
	<div id="app">
		<NavBar></NavBar>
		<div ref="visual" class="visual">
			<keep-alive v-if="$route.meta.keepAlive">
				<router-view/>
			</keep-alive>
			<router-view v-else/>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import NavBar from '@/components/navbar/navbar.vue'
import Footer from '@/components/footer/footer.vue'

export default {
	components: {NavBar, Footer},
	// mounted() {
	//   if (this.$route.meta.isPosition) {
	//     this.$refs.visual.style.marginTop = '290px'
	//   }
	//   console.log(this.$route)
	// },
	mounted() {
		if (this._isMobile()) {
			window.open('https://www.jstjxh.com/h5', '_self')
		}
	},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		}
	},
	watch: {
		'$store.state.listId': {
			handler(val) {
				sessionStorage.setItem('listId', val)
			},
			deep: true
		},
		$route(val) {
			if (val.path == '/home') {
				this.$refs.visual.style.marginTop = '210px'
			} else {
				this.$refs.visual.style.marginTop = '0'
			}
			// if (val.meta.isPosition) {
			//   // this.$refs.visual.style.marginTop = '290px'
			// } else {
			//   // this.$refs.visual.style.marginTop = '210px'
			// }
		}
	}
}
</script>

<style lang="less">
@import url('~@/assets/css/global.less');
</style>
